import React from 'react';
import Helmet from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';
import Layout from '../components/layout';
import Banner from '../components/Banner';
import Gallery from '../components/Gallery';

const Stands = () => (
  <Layout>
    <Helmet
      title="Velgama - Stendai"
      meta={[
        { name: 'description', content: 'Kompleksinė reklama' },
      ]}
    />
    <div id="main">
      <Banner h1="Stendai" paragraph="Kompleksinė reklama" bannerType="stands-banner" />
      <section id="one" className="spotlights">
        <StaticQuery
          query={galleryQuery}
          render={(data) => <Gallery data={data} />}
        />

      </section>
    </div>
  </Layout>
);

const galleryQuery = graphql`
query standsImages {
    allFile(
      filter: { sourceInstanceName: { eq: "images-stands" } },
      sort: {order: ASC, fields: name}
      ) {
      edges {
        node {
          dir
          name
          childImageSharp{
            fluid (maxWidth: 1200, maxHeight: 1000){
                ...GatsbyImageSharpFluid
              }
          }
        }
      }
    }
  }
`;

export default Stands;
